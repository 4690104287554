@font-face {
  font-family: 'Whitney';
  font-style: normal;
  font-display: swap;
  src: 
  url('./fonts/Whitney/whitneymedium.otf') format('opentype'); 

  /* url('./fonts/Whitney/whitneybold.otf') format('opentype'),
  url('./fonts/Whitney/whitneylight.otf') format('opentype'), 
  url('./fonts/Whitney/whitneybookitalic.otf') format('opentype'), 
  url('./fonts/Whitney/whitneymediumitalic.otf') format('opentype'),  
  url('./fonts/Whitney/whitneymedium.otf') format('opentype'),
  url('./fonts/Whitney/whitneylightitalic.otf') format('opentype'), 
  url('./fonts/Whitney/WhitneySemiboldItalic.otf') format('opentype'), 
  url('./fonts/Whitney/whitneybook.otf') format('opentype'), 
  url('./fonts/Whitney/whitneysemibold.otf') format('opentype'); */
}